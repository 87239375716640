// src/ButtonHandlers.js
import { useState } from 'react';

const useHandlers = (crosswordRef) => {
  const [showBalloons, setShowBalloons] = useState(false);

  const handleReset = () => {
    crosswordRef.current.reset();
    setShowBalloons(false);
  };

  const handleFillAllAnswers = () => {
    crosswordRef.current.fillAllAnswers();
  };

  const handleCheckCorrect = () => {
    const isCorrect = crosswordRef.current.isCrosswordCorrect();
    if (isCorrect) {
      console.log('Correct!');
      setShowBalloons(true);
      setTimeout(() => setShowBalloons(false), 4000);
    }
  };

  return {
    handleReset,
    handleFillAllAnswers,
    handleCheckCorrect,
    showBalloons,
  };
};

export default useHandlers;
