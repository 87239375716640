import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SendIcon from '@mui/icons-material/Send';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandIcon from '@mui/icons-material/Expand';


export default function FloatingActionButtons({ handleReset, handleFillAllAnswers, handleCheckCorrect }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleShareClick = () => {
    const shareLink = window.location.href; // Get current URL
    navigator.clipboard.writeText(shareLink).then(() => {
      setSnackbarOpen(true); // Show Snackbar
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const buttons = (
    <>
      <Tooltip title="Restart the puzzle" arrow>
        <Fab color="inherit" aria-label="restart" onClick={handleReset}>
          <RestartAltIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Fill all answers" arrow>
        <Fab color="inherit" aria-label="help" onClick={handleFillAllAnswers}>
          <PsychologyAltIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Check correct answers" arrow>
        <Fab variant="inherit" aria-label="check" onClick={handleCheckCorrect}>
          <DoneOutlineIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Share the puzzle" arrow>
        <Fab variant="inherit" aria-label="share" onClick={handleShareClick}>
          <SendIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Like this puzzle" arrow>
        <Fab variant="inherit" aria-label="like">
          <FavoriteIcon />
        </Fab>
      </Tooltip>
    </>
  );

  return (
    <>
      {isSmallScreen ? (
        <>
          <Fab color="primary" aria-label="menu" onClick={handleDrawerToggle}>
            <ExpandIcon/>
          </Fab>
          <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, '& > *': { mb: 1 } }}>
              {buttons}
            </Box>
          </Drawer>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', '& > :not(style)': { m: 1 } }}>
          {buttons}
        </Box>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Link copied to clipboard!
        </MuiAlert>
      </Snackbar>
    </>
  );
}
