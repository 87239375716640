import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Crossword, { ThemeProvider } from '@jaredreisinger/react-crossword';
import BasicTextFields from './Input';
import FloatingActionButtons from './Buttons';
import { generateLayout } from './layout_generator';
import useHandlers from './ButtonHandlers';

const defaultData = {
  title: 'Coffee, Please!',
  puzzle: [
    { answer: 'MPS', clue: 'Most Perfect Sip' },
    { answer: 'ESPRESSO', clue: 'Strong coffee made by forcing steam through ground coffee beans' },
    { answer: 'CAPPUCCINO', clue: 'Coffee drink with steamed milk foam' },
    { answer: 'LATTE', clue: 'Coffee with steamed milk' },
    { answer: 'ROAST', clue: 'Process of cooking coffee beans' },
    { answer: 'ETHIOPIA', clue: 'Coffee originated in the region of ____, in East Africa' },
  ],
};

const adaptLibraryOutput = (words, decodedPuzzle) => {
  const formattedData = {
    across: {},
    down: {},
  };

  words.forEach((word, index) => {
    const direction = word.orientation === 'across' ? 'across' : 'down';
    formattedData[direction][index + 1] = {
      clue: decodedPuzzle.find((puzzleWord) => puzzleWord.answer === word.answer).clue,
      answer: word.answer,
      row: word.starty - 1,
      col: word.startx - 1,
    };
  });

  return formattedData;
};

const CrosswordPuzzle = () => {
  const location = useLocation();
  const crosswordRef = useRef(null);
  const prevParams = useRef('');
  const [puzzleData, setPuzzleData] = useState(
    adaptLibraryOutput(
      generateLayout(
        defaultData.puzzle.map((word) => ({
          answer: word.answer,
          length: word.answer.length,
        }))
      ).result,
      defaultData.puzzle
    )
  );
  const [title, setTitle] = useState(defaultData.title);
  const { handleReset, handleFillAllAnswers, handleCheckCorrect, showBalloons } = useHandlers(crosswordRef);

  const fetchPuzzleData = async (key) => {
    try {
      const response = await fetch(`https://api.llmer.com/get_data/${key}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return JSON.parse(atob(data.data));
    } catch (error) {
      console.error('Failed to fetch puzzle data:', error);
      return null;
    }
  };

  const resetCrossword = () => {
    crosswordRef.current.reset();
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const key = queryParams.get('key');

    const loadData = async () => {
      let decodedData = defaultData;
      if (key) {
        decodedData = await fetchPuzzleData(key) || defaultData;
      }
      const decodedPuzzle = decodedData.puzzle;
      const decodedTitle = decodedData.title;

      if (prevParams.current !== queryParams.toString()) {
        const layout = generateLayout(
          decodedPuzzle.map((word) => ({
            answer: word.answer,
            length: word.answer.length,
          }))
        );
        setPuzzleData(adaptLibraryOutput(layout.result, decodedPuzzle));
        setTitle(decodedTitle);
        resetCrossword();
      }
      prevParams.current = queryParams.toString();
    };

    loadData();
    resetCrossword();
  }, [location.search]);

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold mb-4 text-center">
        <BasicTextFields />
      </h1>
      <h1 className="text-4xl font-bold mb-4 text-center">Crossword Puzzler</h1>
      <h2 className="text-2xl font-bold mb-4 text-center">{title}</h2>
      <div className="flex flex-col lg:flex-row gap-8">
        <FloatingActionButtons
          handleReset={handleReset}
          handleFillAllAnswers={handleFillAllAnswers}
          handleCheckCorrect={handleCheckCorrect}
        />
        <ThemeProvider
          theme={{
            allowNonSquare: true,
            gridBackground: '#acf',
            cellBackground: '#ffe',
            numberColor: '#000',
            highlightBackground: '#f99',
          }}
        >
          <Crossword ref={crosswordRef} data={puzzleData} />
        </ThemeProvider>
      </div>
      {showBalloons && Array.from({ length: 20 }, (_, i) => (
        <div key={i} className="balloon" style={{ left: `${5 + 5 * i}%` }} role="img" aria-label="balloon">
          🎈
        </div>
      ))}
    </div>
  );
};

export default CrosswordPuzzle;
