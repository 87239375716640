import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

export default function BasicTextFields() {
    const [inputThemes, setInputThemes] = useState('');
    const [difficulty, setDifficulty] = useState('easy');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setInputThemes(event.target.value);
    };

    const handleDifficultyChange = (event) => {
        setDifficulty(event.target.value);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleButtonClick = async () => {
        setLoading(true);
        const payload = {
            input_themes: inputThemes,
            difficulty: difficulty,
        };

        try {
            const response = await fetch('https://api.llmer.com/generate_puzzle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                if (response.status === 429) {
                    throw new Error('You are being rate-limited. Please try again later.');
                } else {
                    throw new Error('Network response was not ok');
                }
            }

            const data = await response.json();
            const key = data.key;

            navigate(`/?key=${key}`);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
        setLoading(false);
    };

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > :not(style)': { m: 1, width: { xs: '100%', sm: '50ch' } },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="outlined-basic"
                label="Generate new puzzle"
                variant="outlined"
                multiline
                rows={4}
                placeholder="Coffee shops, coffee, coffee beans, coffee roasters, coffee brewing, coffee equipment, coffee culture, coffee history, coffee recipes"
                value={inputThemes}
                onChange={handleInputChange}
            />
            <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item xs={12} sm={6}>
                    <Select
                        fullWidth
                        value={difficulty}
                        onChange={handleDifficultyChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ height: '100%' }}
                    >
                        <MenuItem value="easy">Easy</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="hard">Hard</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        onClick={handleButtonClick}
                        loading={loading}
                        sx={{ height: '100%' }}
                    >
                        Generate
                    </LoadingButton>
                </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}